<template>
  <v-card>
    <v-card-title>
      <v-container fluid class="pa-0">
        <v-form ref="form">
          <v-row>
            <v-col>
              Keyword relocations
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-menu
                ref="creationDateMenu"
                v-model="showCreationDateMenu"
                :close-on-content-click="false"
                :return-value.sync="creationDateRange"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="creationDateRangeText"
                    label="Creation date"
                    prepend-icon="mdi-calendar"
                    append-icon="clear"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="clearCreationDateRange"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip>
                        <template v-slot:activator="{ on }">
                          <v-icon class="ml-1" v-on="on">help</v-icon>
                        </template>
                        <span>Select a single date as a "from" filter, or two dates to filter a range
                        </span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="creationDateRange" range no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="showCreationDateMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.creationDateMenu.save(creationDateRange)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col>
              <v-autocomplete v-model="selectedStati" :items="stati" multiple label="Status" 
                clearable prepend-icon="timelapse">
              </v-autocomplete>
            </v-col>

            <v-col>
              <v-text-field v-model="keywordSearch" label="Keyword search" clearable prepend-icon="text_snippet">
              </v-text-field>
            </v-col>

            <v-col>
              <v-btn class="mt-2" color="primary" @click="show">Show</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      show-expand
      single-expand
      class="elevation-1"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div :class="getStatusClass(item.status)">{{ item.status }}</div>
      </template>

      <template v-slot:[`item.destination_campaign_names_csv`]="{ item }">
        <ul v-if="item.destination_campaign_names_csv">
          <li v-for="camp in item.destination_campaign_names_csv.split(',')" :key="camp">
            {{ camp}}
          </li>
        </ul>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-row>
              <v-col md="12">
                <span><strong>Batch #{{ item.batch }} - Relocation ID #{{ item.id }}</strong></span>
                <v-simple-table>
                  <tr>
                    <td width="280">Creation date</td>
                    <td>{{ item.entry_at }}</td>
                  </tr>
                  <tr>
                    <td width="280">Topic</td>
                    <td>{{ item.ad_group_topic }}</td>
                  </tr>
                  <tr>
                    <td># baseline ad group topic and segment matches</td>
                    <td>{{ item.baseline_ad_group_ids_count }}</td>
                  </tr>
                  <tr>
                    <td># of those where ad group segment labels match campaign's</td>
                    <td>{{ item.matching_ad_group_ids_count }}</td>
                  </tr>
                  <tr>
                    <td># of those which contain keyword ID</td>
                    <td>{{ item.ad_groups_count }}</td>
                  </tr>
                  <tr>
                    <td>Source ad groups containing keyword ID</td>
                    <td>
                      <v-simple-table v-if="item.ad_groups_csv">
                        <tr>
                          <th>Ad group</th>
                          <th>Source KW</th>
                          <th>Source NKW?</th>
                          <th>Destination campaign</th>
                          <th>Dest KW?</th>
                          <th>Dest NKW?</th>
                          <th>Errors</th>
                          <th>Completed at</th>
                        </tr>
                        <tr v-for="ag in item.ad_groups_csv.split(',')" :key="ag">
                            <td v-for="(val,idx) in ag.split(';')" :key="idx">
                              {{ val }}
                            </td>
                        </tr>
                      </v-simple-table>
                    </td>
                  </tr>
                  <tr v-if="item.error_msg">
                    <td>Error</td>
                    <td><div class="failed">{{ item.error_msg }}</div></td>
                  </tr>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
          
        </td>
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'

export default {
  name: 'KeywordRelocationTable',

  props: {
    pAccountID: { type: Number, required: true },
  },

  data () {
    return {
      headers: [
        { text: 'Keyword', value: 'keyword_text' },
        { text: 'Keyword ID', value: 'keyword_id' },
        { text: 'Source campaign', value: 'source_campaign_name' },
        { text: '# Ad Grps', value: 'ad_groups_count' },
        { text: 'Lbl1', value: 'label1' },
        { text: 'Lbl2', value: 'label2' },
        { text: 'Seg1', value: 'segment1' },
        { text: 'Seg2', value: 'segment2' },
        { text: 'Status', value: 'status' },
        { text: 'Destination campaign(s)', value: 'destination_campaign_names_csv' },
        { text: '', value: 'data-table-expand' },
      ],
      items: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      keywordSearch: '',
      showCreationDateMenu: false,
      creationDateRange: [],
      selectedStati: [],
      lsOptions: 'keywordRelocation_options',
      lsFilters: 'keywordRelocation_filters',
      kwrEndpoint: '/a/core/keyword-relocations',
    }
  },

  mixins: [dataTablesMixin],

  computed: {
    creationDateRangeText () {
      if (this.creationDateRange.length == 1) {
        return 'from ' + this.creationDateRange[0]
      }
      return this.creationDateRange.join(' - ')
    },

    stati () {
      return this.$store.state.core.keywordRelocationStati
    },
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
    pAccountID: {
      handler () {
        this.fetchData()
      }
    },
  },

  methods: {
    clearCreationDateRange: function () {
      this.creationDateRange = []
    },

    fetchData: function () {
      if (!this.pAccountID) {
        return
      }

      this.loading = true
      var myURL = this.kwrEndpoint + '?xfields='

      // get fields from headers
      var additionalCols = ['batch', 'id', 'ad_group_topic', 'baseline_ad_group_ids_count', 'matching_ad_group_ids_count', 'ad_groups_csv', 'error_msg', 'entry_at']
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, ['data-table-expand'], additionalCols)

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // filter account (mandatory)
      myURL += '&account_id=' + this.pAccountID

      // filter creation date
      if (this.creationDateRange.length > 0) {

        // 1st date is always from
        myURL += '&entry_at=>' + this.creationDateRange[0]

        // if 2nd date passed, treat as until
        if (this.creationDateRange.length > 1) {

          // add one day to make the end date shown inclusive
          var untilDateObj = this.$dateTime.fromSQL(this.creationDateRange[1])
          untilDateObj = untilDateObj.plus({ days: 1})
          myURL += '&entry_at=<' + untilDateObj.toISODate()
        }
      }

      // filter status
      if (this.selectedStati.length > 0) {
        myURL += '&status=' + this.selectedStati.join('|')
      }

      // filter keyword search
      if (this.keywordSearch) {
        myURL += '&keyword_text=~' + this.keywordSearch + '~'
      }

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    show: function () {
      let filterObj = {
        'selectedAccount': this.selectedAccount,
        'creationDateRange': this.creationDateRange,
        'selectedStati': this.selectedStati,
        'keywordSearch': this.keywordSearch
      }
      localStorage.setItem(this.lsFilters, JSON.stringify(filterObj))

      this.options.page = 1;
      this.fetchData()
    },

    getStatusClass: function (status) {
      switch (status) {
        case 'Completed':
          return 'completed'
        case 'Failed':
          return 'failed'
        case 'Rejected':
          return 'failed'
        case 'No change':
          return 'noChange'
        case 'Ready':
          return 'ready'
      }
    },
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }

    // if present, use local filters
    if (localStorage.getItem(this.lsFilters)) {
      let filters = JSON.parse(localStorage.getItem(this.lsFilters))
      if (filters['selectedAccount']) { this.selectedAccount = filters['selectedAccount'] }
      if (filters['creationDateRange']) { this.creationDateRange = filters['creationDateRange'] }
      if (filters['selectedStati']) { this.selectedStati = filters['selectedStati'] }
      if (filters['keywordSearch']) { this.keywordSearch = filters['keywordSearch'] }
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>

<style scoped>

div.completed {
  color: green;
}
div.failed {
  color: red;
}
div.noChange {
  color: grey;
}
div.ready {
  color: blue;
}

th {
  text-align: left;
}

</style>