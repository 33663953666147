var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',[_vm._v(" Keyword relocations ")])],1),_c('v-row',[_c('v-col',[_c('v-menu',{ref:"creationDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.creationDateRange,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.creationDateRange=$event},"update:return-value":function($event){_vm.creationDateRange=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Creation date","prepend-icon":"mdi-calendar","append-icon":"clear","readonly":""},on:{"click:append":_vm.clearCreationDateRange},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1"},on),[_vm._v("help")])]}}],null,true)},[_c('span',[_vm._v("Select a single date as a \"from\" filter, or two dates to filter a range ")])])]},proxy:true}],null,true),model:{value:(_vm.creationDateRangeText),callback:function ($$v) {_vm.creationDateRangeText=$$v},expression:"creationDateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showCreationDateMenu),callback:function ($$v) {_vm.showCreationDateMenu=$$v},expression:"showCreationDateMenu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":""},model:{value:(_vm.creationDateRange),callback:function ($$v) {_vm.creationDateRange=$$v},expression:"creationDateRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showCreationDateMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.creationDateMenu.save(_vm.creationDateRange)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.stati,"multiple":"","label":"Status","clearable":"","prepend-icon":"timelapse"},model:{value:(_vm.selectedStati),callback:function ($$v) {_vm.selectedStati=$$v},expression:"selectedStati"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Keyword search","clearable":"","prepend-icon":"text_snippet"},model:{value:(_vm.keywordSearch),callback:function ($$v) {_vm.keywordSearch=$$v},expression:"keywordSearch"}})],1),_c('v-col',[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary"},on:{"click":_vm.show}},[_vm._v("Show")])],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"footer-props":{
      'items-per-page-options': _vm.datatables_rowsPerPageItems,
    },"server-items-length":_vm.totalItems,"show-expand":"","single-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" Loading items.. ")]},proxy:true},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('div',{class:_vm.getStatusClass(item.status)},[_vm._v(_vm._s(item.status))])]}},{key:"item.destination_campaign_names_csv",fn:function(ref){
    var item = ref.item;
return [(item.destination_campaign_names_csv)?_c('ul',_vm._l((item.destination_campaign_names_csv.split(',')),function(camp){return _c('li',{key:camp},[_vm._v(" "+_vm._s(camp)+" ")])}),0):_vm._e()]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('span',[_c('strong',[_vm._v("Batch #"+_vm._s(item.batch)+" - Relocation ID #"+_vm._s(item.id))])]),_c('v-simple-table',[_c('tr',[_c('td',{attrs:{"width":"280"}},[_vm._v("Creation date")]),_c('td',[_vm._v(_vm._s(item.entry_at))])]),_c('tr',[_c('td',{attrs:{"width":"280"}},[_vm._v("Topic")]),_c('td',[_vm._v(_vm._s(item.ad_group_topic))])]),_c('tr',[_c('td',[_vm._v("# baseline ad group topic and segment matches")]),_c('td',[_vm._v(_vm._s(item.baseline_ad_group_ids_count))])]),_c('tr',[_c('td',[_vm._v("# of those where ad group segment labels match campaign's")]),_c('td',[_vm._v(_vm._s(item.matching_ad_group_ids_count))])]),_c('tr',[_c('td',[_vm._v("# of those which contain keyword ID")]),_c('td',[_vm._v(_vm._s(item.ad_groups_count))])]),_c('tr',[_c('td',[_vm._v("Source ad groups containing keyword ID")]),_c('td',[(item.ad_groups_csv)?_c('v-simple-table',[_c('tr',[_c('th',[_vm._v("Ad group")]),_c('th',[_vm._v("Source KW")]),_c('th',[_vm._v("Source NKW?")]),_c('th',[_vm._v("Destination campaign")]),_c('th',[_vm._v("Dest KW?")]),_c('th',[_vm._v("Dest NKW?")]),_c('th',[_vm._v("Errors")]),_c('th',[_vm._v("Completed at")])]),_vm._l((item.ad_groups_csv.split(',')),function(ag){return _c('tr',{key:ag},_vm._l((ag.split(';')),function(val,idx){return _c('td',{key:idx},[_vm._v(" "+_vm._s(val)+" ")])}),0)})],2):_vm._e()],1)]),(item.error_msg)?_c('tr',[_c('td',[_vm._v("Error")]),_c('td',[_c('div',{staticClass:"failed"},[_vm._v(_vm._s(item.error_msg))])])]):_vm._e()])],1)],1)],1)],1)]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(_vm.datatables_footerPageText(_vm.totalItemsIsEstimate, _vm.estimatedItems, props))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }